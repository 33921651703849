export const TEST_CASE = "TEST_CASE";

export const GET_CHECK_LOGIN = "GET_CHECK_LOGIN";
export const SET_CHECK_LOGIN = "SET_CHECK_LOGIN";

export const SET_SIGNUP = "SET_SIGNUP";
export const GET_SIGNUP = "GET_SIGNUP";

export const SET_LOGIN = "SET_LOGIN";
export const GET_LOGIN = "GET_LOGIN";

export const SET_OTP = "SET_OTP";
export const GET_OTP = "GET_OTP";

export const GET_NAVIGATE = "GET_NAVIGATE";
export const SET_LOADING = "SET_LOADING";

export const SET_MSA_FIELD = "SET_MSA_FIELD";
export const GET_MSA_FIELD = "GET_MSA_FIELD";

export const GET_APPROVAL_ALL_USERS = "GET_APPROVAL_ALL_USERS";
export const SET_APPROVAL_ALL_USERS = "SET_APPROVAL_ALL_USERS";

export const GET_ADDMSA = "GET_ADDMSA";
export const SET_ADDMSA = "SET_ADDMSA";

export const GET_EDIT_MSA = "GET_EDIT_MSA";

export const GET_RESOURCELIST = "GET_RESOURCELIST";
export const SET_RESOURCELIST = "SET_RESOURCELIST";

export const GET_SKILL = "GET_SKILL";
export const SET_SKILL = "SET_SKILL";

export const GET_CSC = "GET_CSC";
export const SET_CSC = "SET_CSC";

export const GET_ALLMSA = "GET_ALLMSA";
export const SET_ALLMSA = "SET_ALLMSA";

export const GET_COUNTRY = "GET_COUNTRY";
export const SET_COUNTRY = "SET_COUNTRY";

export const GET_ALL_CURRENCY_LIST = "GET_ALL_CURRENCY_LIST";
export const SET_ALL_CURRENCY_LIST = "SET_ALL_CURRENCY_LIST";

export const GET_ORG_PROFILE = "GET_ORG_PROFILE";
export const GET_AGENCY_PROFILE = "GET_AGENCY_PROFILE";

export const SET_AGENCY_PROFILE = "SET_AGENCY_PROFILE";
export const GET_RESOURCE_PROFILE = "GET_RESOURCE_PROFILE";

export const GET_SKILL_LIST = "GET_SKILL_LIST";
export const SET_SKILL_LIST = "SET_SKILL_LIST";

//SOW DETAIL
export const GET_SHOW_LIST = "GET_SOW_LIST";
export const SET_SHOW_LIST = "SET_SOW_LIST";

export const GET_SOW_FIELD = "GET_SOW_FIELD";
export const SET_SOW_FIELD = "SET_SOW_FIELD";

export const GET_SOW_DETAIL = "GET_SOW_DETAIL";
export const SET_SOW_DETAIL = "SET_SOW_DETAIL";

export const EDIT_SOW_DETAIL = "EDIT_SOW_DETAIL";
export const UPDATE_SOW_DETAIL = "UPDATE_SOW_DETAIL";

export const GET_MSA_LIST = "GET_MSA_LIST";
export const SET_MSA_LIST = "SET_MSA_LIST";

export const GET_ORGANISATION_LIST = "GET_ORGANISATION_LIST";
export const SET_ORGANISATION_LIST = "SET_ORGANISATION_LIST";

export const GET_MSA_FILTERS_LIST = "GET_MSA_FILTERS_LIST";
export const SET_MSA_FILTERS_LIST = "SET_MSA_FILTERS_LIST";

export const GET_MSA_DETAIL = "GET_MSA_DETAIL";
export const SET_MSA_DETAIL = "SET_MSA_DETAIL";

export const GOOGLE_KEY_GET = "GOOGLE_KEY_GET";
export const GOOGLE_LOGIN = "GOOGLE_LOGIN";

export const LINKEDIN_LOGIN = "LINKEDIN_LOGIN";

//Agency Profile List
export const GET_AGENCY_PROFILE_LIST = "GET_AGENCY_PROFILE_LIST";
export const SET_AGENCY_PROFILE_LIST = "SET_AGENCY_PROFILE_LIST";

//Organisation Role
export const GET_ORG_ROLE_LIST = "GET_ORG_ROLE_LIST";
export const SET_ORG_ROLE_LIST = "SET_ORG_ROLE_LIST";

export const GET_ORG_ROLE_DETAIL = "GET_ORG_ROLE_DETAIL";
export const SET_ORG_ROLE_DETAIL = "SET_ORG_ROLE_DETAIL";

export const UPDATE_ORG_ROLE_DETAIL = "UPDATE_ORG_ROLE_DETAIL";
export const EDIT_ORG_ROLE = "EDIT_ORG_ROLE";

// setting all listing

export const GET_SETTINGS_COMPANY_LIST = "GET_SETTINGS_COMPANY_LIST";
export const SET_SETTINGS_COMPANY_LIST = "SET_SETTINGS_COMPANY_LIST";

export const GET_BUSINESS_UNIT_LIST = "GET_BUSINESS_UNIT_LIST";
export const SET_BUSINESS_UNIT_LIST = "SET_BUSINESS_UNIT_LIST";

export const GET_COST_CENTER_LIST = "GET_COST_CENTER_LIST";
export const SET_COST_CENTER_LIST = "SET_COST_CENTER_LIST";

export const GET_UNPSC_CODE_LIST = "GET_UNPSC_CODE_LIST";
export const SET_UNPSC_CODE_LIST = "SET_UNPSC_CODE_LIST";

export const GET_TAX_GROUP_LIST = "GET_TAX_GROUP_LIST";
export const SET_TAX_GROUP_LIST = "SET_TAX_GROUP_LIST";

export const GET_EXPERIENCE_CATEGORY_LIST = "GET_EXPERIENCE_CATEGORY_LIST";
export const SET_EXPERIENCE_CATEGORY_LIST = "SET_EXPERIENCE_CATEGORY_LIST";

export const GET_TAX_SERVICE_LIST = "GET_TAX_SERVICE_LIST";
export const SET_TAX_SERVICE_LIST = "SET_TAX_SERVICE_LIST";

export const GET_GL_ACCOUNT_LIST = "GET_GL_ACCOUNT_LIST";
export const SET_GL_ACCOUNT_LIST = "SET_GL_ACCOUNT_LIST";

export const GET_PAYMENT_TERM_LIST = "GET_PAYMENT_TERM_LIST";
export const SET_PAYMENT_TERM_LIST = "SET_PAYMENT_TERM_LIST";

export const GET_MSA_TYPE_LIST = "GET_MSA_TYPE_LIST";
export const SET_MSA_TYPE_LIST = "SET_MSA_TYPE_LIST";

export const GET_SOW_TYPE_LIST = "GET_SOW_TYPE_LIST";
export const SET_SOW_TYPE_LIST = "SET_SOW_TYPE_LIST";

export const GET_INCOME_TAX_SLABS_LIST = "GET_INCOME_TAX_SLABS_LIST";
export const SET_INCOME_TAX_SLABS_LIST = "SET_INCOME_TAX_SLABS_LIST";

export const GET_PRIVACY_SETTINGS_LIST = "GET_PRIVACY_SETTINGS_LIST";
export const SET_PRIVACY_SETTINGS_LIST = "SET_PRIVACY_SETTINGS_LIST";

export const UPDATE_PRIVACY_SETTINGS_LIST = "UPDATE_PRIVACY_SETTINGS_LIST";

// setting new create Add new

export const GET_ADD_COMPANY_LOCATION = "GET_ADD_COMPANY_LOCATION";

export const GET_ADD_BUSINESS_UNIT_LIST = "GET_ADD_BUSINESS_UNIT_LIST";

export const GET_ADD_COST_CENTER_LIST = "GET_ADD_COST_CENTER_LIST";

export const GET_ADD_GL_ACCOUNTS_LIST = "GET_ADD_GL_ACCOUNTS_LIST";

export const GET_ADD_MSA_TYPE = "GET_ADD_MSA_TYPE";

export const GET_ADD_UNPSC_CODE_TYPE = "GET_ADD_UNPSC_CODE_TYPE";

export const GET_ADD_TAX_GROUP = "GET_ADD_TAX_GROUP";

export const GET_ADD_EXPENSE_CATEGORY = "GET_ADD_EXPENSE_CATEGORY";

export const GET_ADD_TAX_SERVICE = "GET_ADD_TAX_SERVICE";

export const GET_ADD_PAYMENT_TERM = "GET_ADD_PAYMENT_TERM";

export const GET_ADD_SOW_TYPE = "GET_ADD_SOW_TYPE";

// Organisation Users

export const GET_ORGANISATION_USERS_LIST = "GET_ORGANISATION_USERS_LIST";
export const SET_ORGANISATION_USERS_LIST = "SET_ORGANISATION_USERS_LIST";

export const GET_ADD_ORGANISATION_USERS = "GET_ADD_ORGANISATION_USERS";

export const DELETE_ORGANISATION_USER = "DELETE_ORGANISATION_USER";

// Agency Users

export const GET_AGENCY_USERS_LIST = "GET_AGENCY_USERS_LIST";
export const SET_AGENCY_USERS_LIST = "SET_AGENCY_USERS_LIST";

export const GET_ADD_AGENCY_USERS = "GET_ADD_AGENCY_USERS";

export const CREATE_INVITE_RESOURCE_USERS = "CREATE_INVITE_RESOURCE_USERS";

export const GET_RESERVE_REQUEST = "GET_RESERVE_REQUEST";

export const GET_ACCEPT_RESERVE_REQUEST = "GET_ACCEPT_RESERVE_REQUEST";

export const GET_RESET_PASSWORD = "GET_RESET_PASSWORD";

export const DELETE_AGENCY_USER = "DELETE_AGENCY_USER";

export const GET_EXPIRING_SOW_MSA_DATA = 'GET_EXPIRING_SOW_MSA_DATA'
export const SET_EXPIRING_SOW_MSA_DATA = 'SET_EXPIRING_SOW_MSA_DATA'

export const GET_REVENUE_EXPENSCE_DATA = 'GET_REVENUE_EXPENSCE_DATA'
export const SET_REVENUE_EXPENSCE_DATA = 'SET_REVENUE_EXPENSCE_DATA'

export const GET_STATISTICS_DASHBOARD_DATA = 'GET_STATISTICS_DASHBOARD_DATA'
export const SET_STATISTICS_DASHBOARD_DATA = 'SET_STATISTICS_DASHBOARD_DATA'

export const GET_TOTAL_SPEND_DATA = 'GET_TOTAL_SPEND_DATA'
export const SET_TOTAL_SPEND_DATA = 'SET_TOTAL_SPEND_DATA'

export const GET_RESOURCE_TOTAL_EARNINGS_DATA = 'GET_RESOURCE_TOTAL_EARNINGS_DATA'
export const SET_RESOURCE_TOTAL_EARNINGS_DATA = 'SET_RESOURCE_TOTAL_EARNINGS_DATA'

export const GET_ANALYZE_CONTRACT = "GET_ANALYZE_CONTRACT";
export const SET_ANALYZE_CONTRACT = "SET_ANALYZE_CONTRACT";

// Organisation Approval Action //

export const GET_MSA_APPROVAL_LIST = "GET_MSA_APPROVAL_LIST";
export const SET_MSA_APPROVAL_LIST = "SET_MSA_APPROVAL_LIST";

export const GET_SOW_APPROVAL_LIST = "GET_SOW_APPROVAL_LIST";
export const SET_SOW_APPROVAL_LIST = "SET_SOW_APPROVAL_LIST";

export const GET_TIMESHEET_APPROVAL_LIST = "GET_TIMESHEET_APPROVAL_LIST";
export const SET_TIMESHEET_APPROVAL_LIST = "SET_TIMESHEET_APPROVAL_LIST";

export const GET_INVOICE_APPROVAL_LIST = "GET_INVOICE_APPROVAL_LIST";
export const SET_INVOICE_APPROVAL_LIST = "SET_INVOICE_APPROVAL_LIST";

export const CALL_APPROVE_STATUS = "CALL_APPROVE_STATUS";

export const MSA_CALL_APPROVE_STATUS = "MSA_CALL_APPROVE_STATUS";

export const TIMESHEET_CALL_APPROVE_STATUS = "TIMESHEET_CALL_APPROVE_STATUS";

export const INVOICE_CALL_APPROVE_STATUS = "INVOICE_CALL_APPROVE_STATUS";

// Setting Edit Functionality //

export const GET_EDIT_UNPSC_CODE = "GET_EDIT_UNPSC_CODE";

export const GET_EDIT_TAX_GROUP = "GET_EDIT_TAX_GROUP";

export const GET_EDIT_EXPENSE_CATEGORY = "GET_EDIT_EXPENSE_CATEGORY";

export const GET_EDIT_TAX_SERVICE_RATE = "GET_EDIT_TAX_SERVICE_RATE";

export const GET_EDIT_COST_CENTER = "GET_EDIT_COST_CENTER";

export const GET_EDIT_GL_ACCOUNTS = "GET_EDIT_GL_ACCOUNTS";

export const GET_EDIT_PAYMENT_TERM = "GET_EDIT_PAYMENT_TERM";

export const GET_EDIT_MSA_TYPE = "GET_EDIT_MSA_TYPE";

export const GET_EDIT_SOW_TYPE = "GET_EDIT_SOW_TYPE";

export const GET_EDIT_BUSINESS_UNIT = "GET_EDIT_BUSINESS_UNIT";

export const GET_EDIT_COMPANY_LOCATION = "GET_EDIT_COMPANY_LOCATION";

//  delete

export const DELETE_EXPENSES_CATEGORY = "DELETE_EXPENSES_CATEGORY";

// Resource profile list

export const GET_RESOURCE_PROFILE_LIST = "GET_RESOURCE_PROFILE_LIST";
export const SET_RESOURCE_PROFILE_LIST = "SET_RESOURCE_PROFILE_LIST";

export const GET_VIEW_RES_PROFILE = "GET_VIEW_RES_PROFILE";
export const SET_VIEW_RES_PROFILE = "SET_VIEW_RES_PROFILE";

export const GET_LOGIN_USER_PROFILE_DETAILS = "GET_LOGIN_USER_PROFILE_DETAILS";
export const SET_LOGIN_USER_PROFILE_DETAILS = "SET_LOGIN_USER_PROFILE_DETAILS";

export const GET_VIEW_AGN_PROFILE = "GET_VIEW_AGN_PROFILE";
export const SET_VIEW_AGN_PROFILE = "SET_VIEW_AGN_PROFILE";

// TIMESHEET_LIST

export const GET_TIMESHEET_LIST = "GET_TIMESHEET_LIST";
export const SET_TIMESHEET_LIST = "SET_TIMESHEET_LIST";

export const SEND_APPROVAL_TIMESHEET = "SEND_APPROVAL_TIMESHEET";

export const GET_VIEW_TIMESHEET_DETAILS = "GET_VIEW_TIMESHEET_DETAILS";
export const SET_VIEW_TIMESHEET_DETAILS = "SET_VIEW_TIMESHEET_DETAILS";

//MSA
export const GET_MSA_DYNAMIC_VARIABLES = "GET_MSA_DYNAMIC_VARIABLES";
export const SET_MSA_DYNAMIC_VARIABLES = "SET_MSA_DYNAMIC_VARIABLES";

//
export const GET_FORMATTED_TEMPLATE = "GET_FORMATTED_TEMPLATE";
export const SET_FORMATTED_TEMPLATE = "SET_FORMATTED_TEMPLATE";

export const GET_DEFAULT_TEMPLATE = "GET_DEFAULT_TEMPLATE";
export const SET_DEFAULT_TEMPLATE = "SET_DEFAULT_TEMPLATE";

export const GET_ALL_MSA_AGREEMENT_TEMPLATE = "GET_ALL_MSA_AGREEMENT_TEMPLATE";
export const SET_ALL_MSA_AGREEMENT_TEMPLATE = "SET_ALL_MSA_AGREEMENT_TEMPLATE";

export const DELETE_AGREEMENT_TEMPLATE = "DELETE_AGREEMENT_TEMPLATE"

export const GET_MSA_AGREEMENT_TEMPLATE_BY_ID =
  "GET_MSA_AGREEMENT_TEMPLATE_BY_ID";
export const SET_MSA_AGREEMENT_TEMPLATE_BY_ID =
  "SET_MSA_AGREEMENT_TEMPLATE_BY_ID";

export const CREATE_MSA_AGREEMENT_TEMPLATE = "CREATE_MSA_AGREEMENT_TEMPLATE";
export const EDIT_MSA_AGREEMENT_TEMPLATE = "EDIT_MSA_AGREEMENT_TEMPLATE";

export const EDIT_MSA_DEFAULT_AGREEMENT_TEMPLATE =
  "EDIT_MSA_DEFAULT_AGREEMENT_TEMPLATE";
export const SEND_MSA_AGREEMENT_TEMPLATE = "SEND_MSA_AGREEMENT_TEMPLATE";

// sow template editor

export const GET_SOW_FORMATTED_TEMPLATE = "GET_SOW_FORMATTED_TEMPLATE";
export const SET_SOW_FORMATTED_TEMPLATE = "SET_SOW_FORMATTED_TEMPLATE";

export const GET_ALL_SOW_TEMPLATE = "GET_ALL_SOW_TEMPLATE";
export const SET_ALL_SOW_TEMPLATE = "SET_ALL_SOW_TEMPLATE";

export const GET_SOW_TEMPLATE_CHANGE = "GET_SOW_TEMPLATE_CHANGE";
export const SET_SOW_TEMPLATE_CHANGE = "SET_SOW_TEMPLATE_CHANGE";

// invoice management

export const GET_INVOICE_LIST = "GET_INVOICE_LIST";
export const SET_INVOICE_LIST = "SET_INVOICE_LIST";

export const GET_INVOICE_CHARGES_LIST = "GET_INVOICE_CHARGES_LIST";
export const SET_INVOICE_CHARGES_LIST = "SET_INVOICE_CHARGES_LIST";

export const ADD_INVOICE_CHARGES = "ADD_INVOICE_CHARGES";

export const CREATE_NEW_INVOICE = "CREATE_NEW_INVOICE";

export const GET_INVOICE_DETAILS_DATA = "GET_INVOICE_DETAILS_DATA";
export const SET_INVOICE_DETAILS_DATA = "SET_INVOICE_DETAILS_DATA";

export const RESOURCE_SEND_INVOICE_MAIL = "RESOURCE_SEND_INVOICE_MAIL";

export const GET_INVOICE_PDF_DATA = "GET_INVOICE_PDF_DATA";
export const SET_INVOICE_PDF_DATA = "SET_INVOICE_PDF_DATA";

export const POST_PAYMENTS_DATA = "POST_PAYMENTS_DATA";

export const GET_PAYMENTS_INVOICE_DETAILS = "GET_PAYMENTS_INVOICE_DETAILS";
export const SET_PAYMENTS_INVOICE_DETAILS = "SET_PAYMENTS_INVOICE_DETAILS";

export const GET_COST_OF_PAYMENTS_TRANSFER = "GET_COST_OF_PAYMENTS_TRANSFER";
export const SET_COST_OF_PAYMENTS_TRANSFER = "SET_COST_OF_PAYMENTS_TRANSFER";

// Job Posting //

export const GET_JOB_POST_LIST = "GET_JOB_POST_LIST";
export const SET_JOB_POST_LIST = "SET_JOB_POST_LIST";

export const DELETE_JOB_POST = "DELETE_JOB_POST";

export const GET_JOB_POST_DETAILS = "GET_JOB_POST_DETAILS";
export const SET_JOB_POST_DETAILS = "SET_JOB_POST_DETAILS";

export const ADD_JOB_POST = "ADD_JOB_POST";

export const EDIT_JOB_POST = "EDIT_JOB_POST";

export const ADD_NEW_QUESTION = "ADD_NEW_QUESTION";

export const EDIT_QUESTION_JOB_POST = "EDIT_QUESTION_JOB_POST";

export const DELETE_QUESTION = "DELETE_QUESTION";

export const GET_APPLICATION_LIST_DATA = "GET_APPLICATION_LIST_DATA";
export const SET_APPLICATION_LIST_DATA = "SET_APPLICATION_LIST_DATA";

export const APPLICATION_STATUS_CHANGE = "APPLICATION_STATUS_CHANGE";

export const GET_LANGUAGE_LIST = "GET_LANGUAGE_LIST";
export const SET_LANGUAGE_LIST = "SET_LANGUAGE_LIST";

export const GET_EXPERIENCE_LEVEL_LIST = "GET_EXPERIENCE_LEVEL_LIST";
export const SET_EXPERIENCE_LEVEL_LIST = "SET_EXPERIENCE_LEVEL_LIST";

export const ADD_EXPERIENCE_LEVEL_LIST = "ADD_EXPERIENCE_LEVEL_LIST";

export const GET_ORG_USER_DETAILS_DATA = "GET_ORG_USER_DETAILS_DATA";
export const SET_ORG_USER_DETAILS_DATA = "SET_ORG_USER_DETAILS_DATA";

export const GET_AGN_USER_DETAILS_DATA = "GET_AGN_USER_DETAILS_DATA";
export const SET_AGN_USER_DETAILS_DATA = "SET_AGN_USER_DETAILS_DATA";

export const ADD_NEW_SKILLS = "ADD_NEW_SKILLS";

export const GET_ORG_DASHBOARD_DATA = "GET_ORG_DASHBOARD_DATA";
export const SET_ORG_DASHBOARD_DATA = "SET_ORG_DASHBOARD_DATA";

export const GET_AGN_DASHBOARD_DATA = "GET_AGN_DASHBOARD_DATA";
export const SET_AGN_DASHBOARD_DATA = "SET_AGN_DASHBOARD_DATA";

export const GET_RES_DASHBOARD_DATA = "GET_RES_DASHBOARD_DATA"
export const SET_RES_DASHBOARD_DATA = "SET_RES_DASHBOARD_DATA"

export const GET_WORK_AVAILABILITY = "GET_WORK_AVAILABILITY";
export const SET_WORK_AVAILABILITY = "SET_WORK_AVAILABILITY";

export const CREATE_WORK_PREFERENCE = "CREATE_WORK_PREFERENCE";

export const UPDATE_WORK_PREFERENCE = "UPDATE_WORK_PREFERENCE";

export const GET_USER_PROFILE_DETAILS_DATA = "GET_USER_PROFILE_DETAILS_DATA";
export const SET_USER_PROFILE_DETAILS_DATA = "SET_USER_PROFILE_DETAILS_DATA";

//////////////////////////// New Onboarding action /////////////////////////////

export const GET_RES_INITIAL_PROFILE = "GET_RES_INITIAL_PROFILE";
export const SET_RES_INITIAL_PROFILE = "SET_RES_INITIAL_PROFILE";

// Jobs & Profile //

export const GET_JOBS_FILTERS_DATA = "GET_JOBS_FILTERS_DATA";
export const SET_JOBS_FILTERS_DATA = "SET_JOBS_FILTERS_DATA";

export const GET_LATEST_JOBS_DATA = "GET_LATEST_JOBS_DATA";
export const SET_LATEST_JOBS_DATA = "SET_LATEST_JOBS_DATA";

export const GET_PROFILES_FILTERS_DATA = "GET_PROFILES_FILTERS_DATA";
export const SET_PROFILES_FILTERS_DATA = "SET_PROFILES_FILTERS_DATA";

export const APPLY_FOR_JOB_DATA = "APPLY_FOR_JOB_DATA";

export const UPDATE_APPLY_FOR_JOB_DATA = "UPDATE_APPLY_FOR_JOB_DATA";

export const UPLOAD_MSA_AGREEMENT_MEDIA = "UPLOAD_MSA_AGREEMENT_MEDIA";
export const SET_MSA_AGREEMENT_MEDIA = "SET_MSA_AGREEMENT_MEDIA";

// msa download pdf

export const GET_DOWNLOAD_MSA_PDF_DATA = "GET_DOWNLOAD_MSA_PDF_DATA";
export const SET_DOWNLOAD_MSA_PDF_DATA = "SET_DOWNLOAD_MSA_PDF_DATA";

export const GET_DOC_TYPE_CONTENT = "GET_DOC_TYPE_CONTENT";
export const SET_DOC_TYPE_CONTENT = "SET_DOC_TYPE_CONTENT";

export const CHANGE_DRAFT_PRINT_DOWNLOAD_DATA = "CHANGE_DRAFT_PRINT_DOWNLOAD_DATA";

export const GET_DOWNLOAD_SOW_PDF_DATA = "GET_DOWNLOAD_SOW_PDF_DATA";
export const SET_DOWNLOAD_SOW_PDF_DATA = "SET_DOWNLOAD_SOW_PDF_DATA";


// FORGOT 

export const FORGOT_SEND_EMAIL = "FORGOT_SEND_EMAIL"
export const SET_FORGOT_SEND_EMAIL = "SET_FORGOT_SEND_EMAIL"

export const VERITY_FORGOT_OTP = "VERITY_FORGOT_OTP"
export const SET_VERITY_FORGOT_OTP = "SET_VERITY_FORGOT_OTP"

// Ratings Review

export const GET_SINGLE_USER_RATING_REVIEW = "GET_SINGLE_USER_RATING_REVIEW"
export const SET_SINGLE_USER_RATING_REVIEW = "SET_SINGLE_USER_RATING_REVIEW"

export const POST_RATINGS_REVIEW_DATA = "POST_RATINGS_REVIEW_DATA"

export const EDIT_RATINGS_REVIEW_DATA = "EDIT_RATINGS_REVIEW_DATA"

export const USER_REVIEW_RATING_DELETE = "USER_REVIEW_RATING_DELETE"

export const GET_EDIT_DETAILS_RATING_REVIEW = "GET_EDIT_DETAILS_RATING_REVIEW"
export const SET_EDIT_DETAILS_RATING_REVIEW = "SET_EDIT_DETAILS_RATING_REVIEW"

// excel file

export const GET_MSA_DATA_EXCEL = "GET_MSA_DATA_EXCEL"
export const SET_MSA_DATA_EXCEL = "SET_MSA_DATA_EXCEL"

export const GET_SOW_DATA_EXCEL = "GET_SOW_DATA_EXCEL"
export const SET_SOW_DATA_EXCEL = "SET_SOW_DATA_EXCEL"

export const GET_TIMESHEET_DATA_EXCEL = "GET_TIMESHEET_DATA_EXCEL"
export const SET_TIMESHEET_DATA_EXCEL = "SET_TIMESHEET_DATA_EXCEL"

export const GET_INVOICE_DATA_EXCEL = "GET_INVOICE_DATA_EXCEL"
export const SET_INVOICE_DATA_EXCEL = "SET_INVOICE_DATA_EXCEL"

export const GET_ROLE_DATA_EXCEL = "GET_ROLE_DATA_EXCEL"
export const SET_ROLE_DATA_EXCEL = "SET_ROLE_DATA_EXCEL"

export const GET_USERS_DATA_EXCEL = "GET_USERS_DATA_EXCEL"
export const SET_USERS_DATA_EXCEL = "SET_USERS_DATA_EXCEL"

export const GET_MY_TEAM_USER_DETAILS = "GET_MY_TEAM_USER_DETAILS";
export const SET_MY_TEAM_USER_DETAILS = "SET_MY_TEAM_USER_DETAILS";

export const GET_ANALYZE_BY_AI = "GET_ANALYZE_BY_AI";
export const SET_ANALYZE_BY_AI = "SET_ANALYZE_BY_AI";

export const GET_ORG_BY_ID = "GET_ORG_BY_ID";
export const SET_ORG_BY_ID = "SET_ORG_BY_ID";

//common requests
export const GET_COMMON_REQ = "GET_COMMON_REQ";
export const SET_COMMON_REQ = "SET_COMMON_REQ";

export const GET_NOTIFICATION_REQ = "GET_NOTIFICATION_REQ";
export const SET_NOTIFICATION_REQ = "SET_NOTIFICATION_REQ";

export const GET_UPDATE_NOTIFICATION_REQ = "GET_UPDATE_NOTIFICATION_REQ";
export const SET_UPDATE_NOTIFICATION_REQ = "SET_UPDATE_NOTIFICATION_REQ";

export const GET_ACCEPT_REJECT_CONNECTION_REQ = "GET_ACCEPT_REJECT_CONNECTION_REQ";
export const SET_ACCEPT_REJECT_CONNECTION_REQ = "SET_ACCEPT_REJECT_CONNECTION_REQ";

export const GET_DELETE_CONNECTION_REQ = "GET_DELETE_CONNECTION_REQ";
export const SET_DELETE_CONNECTION_REQ = "SET_DELETE_CONNECTION_REQ";

export const GET_ALL_PLANS = "GET_ALL_PLANS";
export const SET_ALL_PLANS = "SET_ALL_PLANS";

export const GET_PURCHASE_PLAN = "GET_PURCHASE_PLAN";
export const SET_PURCHASE_PLAN = "SET_PURCHASE_PLAN";

export const GET_CURRENT_PLAN = "GET_CURRENT_PLAN";
export const SET_CURRENT_PLAN = "SET_CURRENT_PLAN";

export const GET_SAVE_AI_TEMPLATE = "GET_SAVE_AI_TEMPLATE";
export const SET_SAVE_AI_TEMPLATE = "SET_SAVE_AI_TEMPLATE";

export const GET_UPDATE_AI_TEMPLATE = "GET_UPDATE_AI_TEMPLATE";
export const SET_UPDATE_AI_TEMPLATE = "SET_UPDATE_AI_TEMPLATE";

export const GET_DELETE_AI_TEMPLATE = "GET_DELETE_AI_TEMPLATE";
export const SET_DELETE_AI_TEMPLATE = "SET_DELETE_AI_TEMPLATE";

export const GET_DOWNLOAD_AI_TEMPLATE_PDF = "GET_DOWNLOAD_AI_TEMPLATE_PDF";
export const SET_DOWNLOAD_AI_TEMPLATE_PDF = "SET_DOWNLOAD_AI_TEMPLATE_PDF";

export const GET_DOWNLOAD_AI_TEMPLATE_DOCX = "GET_DOWNLOAD_AI_TEMPLATE_DOCX";
export const SET_DOWNLOAD_AI_TEMPLATE_DOCX = "SET_DOWNLOAD_AI_TEMPLATE_DOCX";

export const GET_SEND_ESIGN_DOCS = "GET_SEND_ESIGN_DOCS";
export const SET_SEND_ESIGN_DOCS = "SET_SEND_ESIGN_DOCS";

export const GET_ALL_ESIGN_DOCS = "GET_ALL_ESIGN_DOCS";
export const SET_ALL_ESIGN_DOCS = "SET_ALL_ESIGN_DOCS";


export const GET_CHECK_ESIGN_DOCS = "GET_CHECK_ESIGN_DOCS";
export const SET_CHECK_ESIGN_DOCS = "SET_CHECK_ESIGN_DOCS";

export const GET_COMPLETED_ESIGN_DOCS = "GET_COMPLETED_ESIGN_DOCS";
export const SET_COMPLETED_ESIGN_DOCS = "SET_COMPLETED_ESIGN_DOCS";

export const GET_UPDATE_ESIGN_DOCS = "GET_UPDATE_ESIGN_DOCS";
export const SET_UPDATE_ESIGN_DOCS = "SET_UPDATE_ESIGN_DOCS";

export const GET_DELTEE_ESIGN_DOCS = "GET_DELTEE_ESIGN_DOCS";
export const SET_DELTEE_ESIGN_DOCS = "SET_DELTEE_ESIGN_DOCS";



